/* @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap" rel="styleshee'); */
body {
  font-family: "Manrope", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: large;
}

nav {
  z-index: 10001 !important;
}

.gradientBg {
  background: #ccf0ff;
}
